import React, { useEffect, useRef, useState } from 'react'
import { getClientToken, processPayment, uploadRecording } from '../../API/api';
import DropIn from 'braintree-web-drop-in-react';
import { useLocation, useNavigate } from 'react-router-dom'
import './style.scss'
import TransactionSuccess from '../TransactionSuccess';
import { Base64, getCurrencyFromEuro } from '../../Utilities/helperFunction';
import errorIcon from '../../Assets/error.png'
import { CircularProgress } from '@mui/material';
import { Buffer } from 'buffer';
import AlertDialog from '../../Components/Popup';
import history from "history/browser";


function Payment() {
    const [clientToken, setClientToken] = useState(null)
    const [subString, setSubString] = useState("")
    const [convertedAmount, setConvertedAmount] = useState("")
    const [loading, setLoading] = useState(false)
    const [errorData, setErrorData] = useState({ status: false, msg: "" })
    const [instance, setInstance] = useState("")
    const [transactionID, setTransactionID] = useState("")
    const navigate = useNavigate();
    let location = useLocation();
    const [locationPermission, setLoationPermission] = useState(null);
    const [cameraPermission, setCameraPermission] = useState(null);
    const mediaRecorder = useRef(null);

    const waymoreAnalysisScript = (transactionObj) => {
        routee.handleClickEvents({
            userData: transactionObj,
            event: transactionObj?.event,
            routeeToken: "1f3af2c3-f67e-4377-ba7a-97590b4a4334"
        });
    }

    const callSuccessScript = (id) => {
        if (window?.dataLayer) {
            window.dataLayer = window.dataLayer || []
            const transactionObj = {
                event: 'conversion',
                send_to: 'AW-10932421562/w7EeCOqA4IgYELqH_two',
                transactionDetails: {
                    value: convertedAmount,
                    currency: 'EUR',
                    transaction_id: id
                },
                formData: location?.state?.formData,
                serviceCostDetails: location?.state?.serviceCostDetails
            }
            window.dataLayer.push(transactionObj);
        }
    }

    const callFailureScript = () => {
        if (window?.dataLayer) {
            window.dataLayer = window.dataLayer || []
            const transactionObj = {
                event: 'transaction_failed',
                transactionDetails: {
                    value: convertedAmount,
                    currency: 'EUR',
                },
                formData: location?.state?.formData,
                serviceCostDetails: location?.state?.serviceCostDetails
            }
            window.dataLayer.push(transactionObj);
            window.parent.postMessage(transactionObj, 'https://www.waymore.io/')
            waymoreAnalysisScript(transactionObj);
        }
    }

    const getToken = async () => {
        setLoading(true)
        await getClientToken().then((res) => {
            if (res?.data?.data) {
                setClientToken(Base64.decode(res?.data?.data));
            } else {
                setErrorData({ status: true, msg: "Something Went Wrong" });
                setLoading(false);
            }
        }).catch((err) => { setErrorData({ status: true, msg: "Something Went Wrong" }); setLoading(false) })
        setLoading(false)
    }
    const getCurrencyConverted = async (currency, amount, symbol) => {
        if (currency !== "EUR") {
            let equalentValue = await getCurrencyFromEuro(currency, "EUR", amount)
            equalentValue = equalentValue.toFixed(2)
            const transactionObj = {
                event: "Payment_Started",
                transactionDetails: {
                    value: equalentValue,
                },
                formData: location?.state?.formData,
                serviceCostDetails: location?.state?.serviceCostDetails
            }
            window.parent.postMessage(transactionObj, 'https://www.waymore.io/');
            waymoreAnalysisScript(transactionObj);
            setSubString(` ${equalentValue} € (${amount} ${symbol})`)
            setConvertedAmount(equalentValue)
        } else {
            setSubString(` ${amount} € `)
            setConvertedAmount(amount)
            const transactionObj = {
                event: "Payment_Started",
                transactionDetails: {
                    value: amount,
                },
                formData: location?.state?.formData,
                serviceCostDetails: location?.state?.serviceCostDetails
            }
            window.parent.postMessage(transactionObj, 'https://www.waymore.io/');
            waymoreAnalysisScript(transactionObj);
        }
    }

    useEffect(() => {
        let locData = location?.state
        if (locData) {
            if (locData?.amount && locData?.symbol && locData?.currency) {
                getCurrencyConverted(locData?.currency, locData?.amount, locData?.symbol)
                if (!location?.state?.ReCAPTCHA) {
                    navigate("/captcha", { state: location?.state });
                }
            }
            else {
                navigate("/")
            }
        } else {
            navigate("/")
        }

    }, [location?.state])


    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    mimeType: 'video/mp4', width: { exact: 854 },
                    height: { exact: 480 }
                }, audio: true
            });
            const recorder = new MediaRecorder(stream);

            recorder.ondataavailable = async (event) => {
                if (event?.data && event.data?.size > 0) {
                    const blob = new Blob([event.data], { type: 'video/mp4' });
                    const formData = new FormData();
                    formData.append("file", blob, `recording.mp4`);
                    try {
                        const recordingRes = await uploadRecording(formData)
                        if (recordingRes?.data?.data) {
                            const transactionObj = {
                                event: 'recording',
                                transactionDetails: {
                                    value: await getCurrencyFromEuro(location?.state?.currency, "EUR", location?.state?.amount),
                                    currency: 'EUR',
                                },
                                formData: location.state.formData,
                                recording: recordingRes?.data?.data
                            }
                            waymoreAnalysisScript(transactionObj);
                        }
                    } catch (error) {
                        console.log("upload failed", error);
                    }
                }
            };

            recorder.onstop = () => {
                stream?.getTracks()?.forEach(track => track?.stop());
            }

            recorder.start();
            mediaRecorder.current = recorder;
        } catch (error) {
            console.error('Error accessing webcam:', error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorder && mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
            mediaRecorder.current.stop();
            mediaRecorder.current = null;
        }
    };

    useEffect(() => {
        navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
            setLoationPermission(permissionStatus.state == "granted")
            permissionStatus.onchange = () => {
                setLoationPermission(permissionStatus.state == "granted")
            };
        })

        navigator.permissions.query({ name: 'camera' }).then((permissionStatus) => {
            setCameraPermission(permissionStatus.state == "granted")
            permissionStatus.onchange = () => {
                setCameraPermission(permissionStatus.state == "granted")
                if (mediaRecorder.current) {
                    startRecording();
                }
            };
        })

        window.addEventListener('beforeunload', stopRecording);


    }, []);


    useEffect(() => {

        if ((transactionID || errorData?.status) && mediaRecorder) {
            stopRecording()
        }
        else if (!transactionID || !errorData?.status || !mediaRecorder) {
            startRecording()
        }

        history.listen(({ location, action }) => {
            stopRecording()
        });

    }, [transactionID, errorData])

    useEffect(() => {
        getToken()
    }, [])

    const onPurchase = () => {
        setLoading(true)
        instance.requestPaymentMethod()
            .then(data => {
                if (data) {
                    let nonce = data.nonce;
                    let paymentData = {
                        payment_method_nonce: Base64.encode(nonce),
                        amount: convertedAmount
                    }


                    processPayment(paymentData).then(response => {
                        let responseData = Base64.decode(response?.data?.data);
                        responseData = JSON.parse(responseData || null)
                        if (response?.status === 200 && responseData?.success) {
                            const transactionId = responseData?.transaction?.id;
                            setTransactionID(transactionId)
                            callSuccessScript(transactionId)
                            const sessionObj = {
                                'value': convertedAmount,
                                'currency': 'EUR',
                                'transaction_id': transactionId
                            }
                            const jsonString = JSON.stringify(sessionObj);
                            const base64String = Buffer.from(jsonString).toString('base64');
                            sessionStorage.setItem('transactionDetails', base64String)
                            setTimeout(() => {
                                sessionStorage.removeItem('transactionDetails')
                            }, 1000)
                            setLoading(false)
                        } else {
                            setErrorData({ status: true, msg: responseData?.message })
                            setLoading(false)
                            callFailureScript();
                        }
                    }).catch((err) => { setErrorData({ status: true, msg: err?.data?.data?.message }); callFailureScript(); setLoading(false) })
                }

            }).catch((err) => { setErrorData({ status: true, msg: err?.data?.data?.message }); callFailureScript(); setLoading(false) })
    }

    return (
        <div className='payment' id='payment'>
            {loading ? <CircularProgress style={{ 'color': 'white' }} /> : <></>}
            {errorData?.status ?
                <div className='Error-container'>
                    <div className='error-content'>
                        <span><img src={errorIcon} alt="" /> Transaction Failed</span>
                        <span className='error-reason'> Reason: {errorData?.msg ?? "Something went Wrong"}</span>
                    </div>
                </div>
                :
                <>{transactionID ? <TransactionSuccess formData={location?.state?.formData} serviceCostDetails={location?.state?.serviceCostDetails} convertedAmount={convertedAmount} transactionID={transactionID} /> : <div className='payment-container' id="payment-container">
                    <div className='payment-Header'>Please pay the amount of {subString}</div>
                    {clientToken ? <><DropIn
                        options={{ authorization: clientToken, card: { cardholderName: true } }}
                        onInstance={(instanceData) => setInstance(instanceData)}
                    />
                        <button onClick={onPurchase}>Pay</button>
                    </> :
                        <div><h1>Please wait ...</h1></div>
                    }
                </div>}
                </>
            }
            <AlertDialog open={!locationPermission || !cameraPermission} locationPermission={locationPermission} cameraPermission={cameraPermission} />
        </div>
    )
}

export default Payment