import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Alert from '@mui/material/Alert';
import './style.scss'

function Input({ setExpertText, mock, index, rightIcon, setValue, initialValues, maintainValues, setMaintainValues, isValidUrl, category }) {
  const [valid, setValid] = useState(true)


  const handleNumber = (e, i, name) => {
    const reg = new RegExp("^[0-9]*$");
    if (reg.test(e.target.value)) {
      setValue(initialValues[index - 1][i], e.target.value)
      setMaintainValues({ ...maintainValues, [`${name}`]: e.target.value })
    } else {
      setValid(false);
      setMaintainValues({ ...maintainValues, [`${name}`]: "" })
      setTimeout(() => setValid(true), 3000)
      e.preventDefault()
    }
  }

  useEffect(() => {
    if (category) {
      let text = ""
      category?.map((cato, index) => {
        let subText = cato?.name?.trim()
        subText = subText[0] === "/" ? subText?.replace("/", "") : subText
        subText = index < category?.length - 1 ? (subText + ", ") : (subText)
        text += subText
      })
      setExpertText(text)
    }
  }, [category])

  return (
    <div onClick={() => (index)} className={(!valid || !isValidUrl) ? "alert-animation" : 'input-container'}>
      <div className='input-title'>
        <h1 className='title'><span>{index} <img src={rightIcon} /></span>{mock.title}</h1>
        <div>
          <p>{mock?.description}</p>
        </div>
      </div>
      {
        mock.options.map((moc, i) => {
          let name = ''
          if (initialValues && initialValues[index - 1] && initialValues[index - 1]?.length) {
            name = initialValues[index - 1][i]
          }
          return <div className='input'>
            {moc?.type === 'number' ? <TextField
              id={name}
              label={moc?.label}
              placeholder={moc?.placeholder}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
              error={!valid}
              value={maintainValues[name] ?? ""}
              name={name}
              onChange={(e) => handleNumber(e, i, name)}
            />

              : moc.type === 'url' ?
                <TextField
                  id="standard"
                  label={moc?.label}
                  placeholder={moc?.placeholder}
                  onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name={name}
                  onChange={(e) => { setValue(name, e.target.value); setMaintainValues({ ...maintainValues, [`${name}`]: e?.target?.value }) }}
                  value={maintainValues[name]}
                />
                :

                <TextField
                  id="standard"
                  label={moc?.label}
                  placeholder={moc?.placeholder}
                  onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name={name}
                  onChange={(e) => { setValue(name, e?.target?.value); setMaintainValues({ ...maintainValues, [`${name}`]: e?.target?.value }) }}
                  value={maintainValues[name]}
                />

            }
            <Alert className={isValidUrl ? 'alert' : 'alert warning'} severity="error">Looks like the URL is not valid</Alert>
            <Alert className={valid ? 'alert' : 'alert warning'} severity="error">Number only please</Alert>
          </div>
        })
      }
    </div>
  )
}

export default Input