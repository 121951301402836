import { v4 as uuidv4 } from 'uuid';

// export const mock_data = {
//     style:{
//         background: "https://images.typeform.com/images/dcWvxBDY9BSY/background/large",
//         foregroundColor: 'white',
//         buttonColor: 'rgb(20, 39, 78)',
//         fontFamily: "Manrope, sans-serif",
//         landingPageButtonColor: "#7654ff",
//         landingPageTxtColor: "white",
//         buttonTextColor: 'white',
//     },
//     frontPageContent: {
//         topContent: [
//             `We got some good news for you`,
//             `<h2>Now you can use WayMore <b>for FREE!.</b></h2>`,
//             `You can use the following services with no fees at all:`
//         ],
//         services: [{
//             logo: 'https://firebasestorage.googleapis.com/v0/b/waymore-51c94.appspot.com/o/omnichannel.png?alt=media&token=f7e8cf9e-0418-4dc7-b73a-aec84c06a8e2',
//             title: `Omnichannel campaign manager.`,
//             content: `Create campaigns by combining multiple channels to reach effectively your audience.`
//         },
//         {
//             logo: "https://firebasestorage.googleapis.com/v0/b/waymore-51c94.appspot.com/o/email.png?alt=media&token=f77b65ec-7ae1-4ef5-8e2a-4dd07bb08217",
//             title: `Email Marketing.`,
//             content: `Send free of charge up to 2.000 emails per month.`
//         },
//         {
//             logo: "https://firebasestorage.googleapis.com/v0/b/waymore-51c94.appspot.com/o/push.png?alt=media&token=c23088b1-d28c-4416-99da-12d7d8e13eb7",
//             title: `Push Notification.`,
//             content: `Send unlimited Push Notifications free of charge for up to 30.000 opted-in users.`
//         },

//         ],

//         endContent: `We would like to ask you a few more questions to prepare for you right now a personalized quotation that meets your needs!`,
//         footer: {
//             time: {
//                 text: `Takes 1 minute 30 seconds`,
//             },
//             button: {
//                 text: 'Get a quote',
//                 background: 'purple',
//                 color: 'white'
//             },
//             enterText: 'Press Enter'

//         }

//     },
//     formContent: [
//         {
//             id: uuidv4(),
//             title: "What is your name ?",
//             type: "input",
//             name: 'name',
//             description: "",
//             isDepended: false,
//             options: [
//                 {
//                     label: "First name",
//                     placeholder: "Jane",
//                     type: "text",
//                     name: "firstName",
//                 },
//                 {
//                     label: "Last name",
//                     placeholder: "Smith",
//                     name: "lastName",
//                     type: "text"
//                 }
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: "Hi ${firstName}, Does your business have a website",
//             type: "select",
//             description: "",
//             name: "isWebsite",
//             isDepended: false,
//             options: [
//                 {
//                     label: "Yes",
//                     value: "yes",
//                     id: "2a"
//                 },
//                 {
//                     label: "No",
//                     value: "no",
//                     id: "2b"
//                 }
//             ]
//         },

//         {
//             id: uuidv4(),
//             title: "For which website you are interested in learning, how WayMORE could help you to increase sales and reduce costs ?",
//             name: "websiteLink",
//             type: "input",
//             description: "(e.g. www.abc.com)",
//             isDepended: true,
//             dependedId: '2a',
//             options: [
//                 {
//                     label: "",
//                     placeholder: "https://",
//                     type: "url",
//                     required: false,
//                     name: "websiteLink",
//                 }
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: "For which channel you want to get quote for ?",
//             type: "multi-select",
//             description: "",
//             name: 'channel',
//             required: true,
//             isDepended: false,
//             options: [
//                 {
//                     label: "SMS",
//                     value: "sms",
//                     id: '3a'
//                 },
//                 {
//                     label: "Email",
//                     value: "email",
//                     id: '3b'
//                 },
//                 {
//                     label: "Push Notifications",
//                     value: "Push_Notifications",
//                     id: '3c'
//                 },
//                 {
//                     label: "Viber",
//                     value: "viber",
//                     id: '3d'
//                 },
//                 {
//                     label: "WhatsApp",
//                     value: "WhatsApp",
//                     id: '3e'
//                 },
//                 {
//                     label: "Facebook Messenger",
//                     value: "Facebook_Messenger",
//                     id: '3f'
//                 },
//                 {
//                     label: "Telegram",
//                     value: "Telegram",
//                     id: '3g'
//                 },
//                 {
//                     label: "Voice",
//                     value: "Voice",
//                     id: '3h'
//                 },
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: "How many SMS would you like to send",
//             name: "smsCount",
//             type: "input",
//             isDepended: true,
//             required: true,
//             dependedId: '3a',
//             options: [
//                 {
//                     label: "",
//                     placeholder: "Type your answer here...",
//                     type: "number",
//                     required: true,
//                     name: "smsCount",
//                 }
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: "What is the destination country?",
//             name: "sms_country",
//             required: true,
//             isDepended: true,
//             dependedId: '3a',
//             type: "country",
//             options: []
//         },
//         {
//             id: uuidv4(),
//             title: "How many email addresses do you have in your database?",
//             name: "emailInDatabaseCount",
//             type: "input",
//             isDepended: true,
//             required: true,
//             dependedId: '3b',
//             options: [
//                 {
//                     label: "",
//                     placeholder: "Type your answer here...",
//                     type: "number",
//                     required: true,
//                     name: "emailInDatabaseCount",
//                 }
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: "How many emails do you plan to send per address during the month?",
//             type: "dropdown",
//             name: "emailPerAddress",
//             required: true,
//             isDepended: true,
//             required: true,
//             dependedId: '3b',
//             options: [
//                 {
//                     label: "1-4",
//                     value: "1-4",
//                     id: uuidv4()
//                 },
//                 {
//                     label: "5-12",
//                     value: "5-12",
//                     id: uuidv4()
//                 },
//                 {
//                     label: "13+",
//                     value: "13+",
//                     id: uuidv4()
//                 },
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: "Do you want to use out web interface to send emails?",
//             type: "select",
//             name: "isWebInterface",
//             required: true,
//             isDepended: true,
//             dependedId: '3b',
//             options: [
//                 {
//                     label: "Yes",
//                     value: "yes",
//                     id: uuidv4()
//                 },
//                 {
//                     label: "No",
//                     value: "no",
//                     id: uuidv4()
//                 }
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: "How many push notifications do you plan to send?",
//             type: "input",
//             name: "pushNotificationCount",
//             required: true,
//             isDepended: true,
//             dependedId: '3c',
//             options: [
//                 {
//                     label: "",
//                     placeholder: "Type your answer here...",
//                     type: "number",
//                     required: true,
//                     name: "pushNotificationCount",
//                 }
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: "How many Viber Messages would you like to send?",
//             type: "input",
//             name: "viberMessageCount",
//             isDepended: true,
//             required: true,
//             dependedId: '3d',
//             options: [
//                 {
//                     label: "",
//                     placeholder: "Type your answer here...",
//                     type: "number",
//                     required: true,
//                     name: "viberMessageCount",
//                 }
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: "What is the destination country?",
//             name: "viber_country",
//             required: true,
//             isDepended: true,
//             dependedId: '3d',
//             type: "country",
//             options: []
//         },
//         {
//             id: uuidv4(),
//             title: "How many WhatsApp messages would you like to send?",
//             name: "whatsappMessageCount",
//             type: "input",
//             isDepended: true,
//             required: true,
//             dependedId: '3e',
//             options: [
//                 {
//                     label: "",
//                     placeholder: "Type your answer here...",
//                     required: true,
//                     type: "number",
//                     name: "whatsappMessageCount",
//                 }
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: "How many opted-in users do you have?",
//             type: "input",
//             name: "optedInUsersCount",
//             isDepended: true,
//             required: true,
//             dependedId: '3f',
//             options: [
//                 {
//                     label: "",
//                     placeholder: "Type your answer here...",
//                     required: true,
//                     type: "number",
//                     name: "optedInUsersCount",
//                 }
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: "How many Telegram messages would you like to send?",
//             type: "input",
//             name: "telegramMessageCount",
//             isDepended: true,
//             dependedId: '3g',
//             required: true,
//             options: [
//                 {
//                     label: "",
//                     placeholder: "Type your answer here...",
//                     required: true,
//                     type: "number",
//                     name: "telegramMessageCount",
//                 }
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: "How many Voice messages would you like to broadcast?",
//             type: "input",
//             name: "voiceMessageCount",
//             isDepended: true,
//             dependedId: '3h',
//             options: [
//                 {
//                     label: "",
//                     placeholder: "Type your answer here...",
//                     required: true,
//                     type: "number",
//                     name: "voiceMessageCount",
//                 }
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: "Calls to:",
//             type: "multi-select",
//             description: "",
//             name: 'channel',
//             required: true,
//             isDepended: true,
//             dependedId: '3h',
//             options: [
//                 {
//                     label: "Landline number",
//                     value: "landline_number",
//                     id: '25a'
//                 },
//                 {
//                     label: "Mobile number",
//                     value: "mobile_number",
//                     id: '25b'
//                 },
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: "What is the destination country?",
//             name: "call_country",
//             required: true,
//             isDepended: true,
//             dependedId: '3h',
//             type: "country",
//             options: []
//         },
//         {
//             id: uuidv4(),
//             title: "The cost will be : [service] [number] [price per item] [total cost amount] [currency] Based on the numbers provided we strongly suggest using an omnichannel approach that will perform better while concurrently reduce the cost of the campaign approximately to [ammount -47.8%]",
//             type: "select",
//             name: "sms_costFormat",
//             required: true,
//             isDepended: true,
//             dependedId: '3a',
//             options: [
//                 {
//                     label: "I would like to learn more on how WayMore can help me reduce my costs and increase my sales.",
//                     value: "sms_learnMore",
//                     id: '13a'
//                 },
//                 {
//                     label: "<a href='https://go.waymore.io/signup' target='blank' >Would you like to create an account in WayMore?</a>",
//                     value: "sms_createAccount",
//                     id: '13b'
//                 }
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: "The cost will be : [service] [number] [price per item] [total cost amount] [currency] Based on the numbers provided we strongly suggest using an omnichannel approach that will perform better while concurrently reduce the cost of the campaign approximately to [ammount -47.8%]",
//             type: "select",
//             name: "email_costFormat",
//             isDepended: true,
//             required: true,
//             dependedId: '3b',
//             options: [
//                 {
//                     label: "I would like to learn more on how WayMore can help me reduce my costs and increase my sales.",
//                     value: "email_learnMore",
//                     id: '13a'
//                 },
//                 {
//                     label: "<a href='https://go.waymore.io/signup' target='blank' >Would you like to create an account in WayMore?</a>",
//                     value: "email_createAccount",
//                     id: '13b'
//                 }
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: "The cost will be : [service] [number] [price per item] [total cost amount] [currency] Based on the numbers provided we strongly suggest using an omnichannel approach that will perform better while concurrently reduce the cost of the campaign approximately to [ammount -47.8%]",
//             type: "select",
//             name: "push_notification_costFormat",
//             isDepended: true,
//             required: true,
//             dependedId: '3c',
//             options: [
//                 {
//                     label: "I would like to learn more on how WayMore can help me reduce my costs and increase my sales.",
//                     value: "push_notification_learnMore",
//                     id: '13a'
//                 },
//                 {
//                     label: "<a href='https://go.waymore.io/signup' target='blank' >Would you like to create an account in WayMore?</a>",
//                     value: "push_notification_createAccount",
//                     id: '13b'
//                 }
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: "The cost will be : [service] [number] [price per item] [total cost amount] [currency] Based on the numbers provided we strongly suggest using an omnichannel approach that will perform better while concurrently reduce the cost of the campaign approximately to [ammount -47.8%]",
//             type: "select",
//             name: "viber_costFormat",
//             isDepended: true,
//             required: true,
//             dependedId: '3d',
//             options: [
//                 {
//                     label: "I would like to learn more on how WayMore can help me reduce my costs and increase my sales.",
//                     value: "viber_learnMore",
//                     id: '13a'
//                 },
//                 {
//                     label: "<a href='https://go.waymore.io/signup' target='blank' >Would you like to create an account in WayMore?</a>",
//                     value: "viber_createAccount",
//                     id: '13b'
//                 }
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: "The cost will be : [service] [number] [price per item] [total cost amount] [currency] Based on the numbers provided we strongly suggest using an omnichannel approach that will perform better while concurrently reduce the cost of the campaign approximately to [ammount -47.8%]",
//             type: "select",
//             name: "whatsapp_costFormat",
//             isDepended: true,
//             required: true,
//             dependedId: '3e',
//             options: [
//                 {
//                     label: "I would like to learn more on how WayMore can help me reduce my costs and increase my sales.",
//                     value: "whatsapp_learnMore",
//                     id: '13a'
//                 },
//                 {
//                     label: "<a href='https://go.waymore.io/signup' target='blank' >Would you like to create an account in WayMore?</a>",
//                     value: "whatsapp_createAccount",
//                     id: '13b'
//                 }
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: "The cost will be : [service] [number] [price per item] [total cost amount] [currency] Based on the numbers provided we strongly suggest using an omnichannel approach that will perform better while concurrently reduce the cost of the campaign approximately to [ammount -47.8%]",
//             type: "select",
//             name: "facebook_costFormat",
//             isDepended: true,
//             description: "",
//             dependedId: '3f',
//             options: [
//                 {
//                     label: "I would like to learn more on how WayMore can help me reduce my costs and increase my sales.",
//                     value: "facebook_learnMore",
//                     id: '13a'
//                 },
//                 {
//                     label: "<a href='https://go.waymore.io/signup' target='blank' >Would you like to create an account in WayMore?</a>",
//                     value: "facebook_createAccount",
//                     id: '13b'
//                 }
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: "The cost will be : [service] [number] [price per item] [total cost amount] [currency] Based on the numbers provided we strongly suggest using an omnichannel approach that will perform better while concurrently reduce the cost of the campaign approximately to [ammount -47.8%]",
//             type: "select",
//             name: "telegram_costFormat",
//             isDepended: true,
//             description: "",
//             dependedId: '3g',
//             options: [
//                 {
//                     label: "I would like to learn more on how WayMore can help me reduce my costs and increase my sales.",
//                     value: "telegram_learnMore",
//                     id: '13a'
//                 },
//                 {
//                     label: "<a href='https://go.waymore.io/signup' target='blank' >Would you like to create an account in WayMore?</a>",
//                     value: "telegram_createAccount",
//                     id: '13b'
//                 }
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: "The cost will be : [service] [number] [price per item] [total cost amount] [currency] Based on the numbers provided we strongly suggest using an omnichannel approach that will perform better while concurrently reduce the cost of the campaign approximately to [ammount -47.8%]",
//             type: "select",
//             name: "voice_costFormat",
//             isDepended: true,
//             description: "",
//             dependedId: '3h',
//             options: [
//                 {
//                     label: "I would like to learn more on how WayMore can help me reduce my costs and increase my sales.",
//                     value: "voice_learnMore",
//                     id: '13a'
//                 },
//                 {
//                     label: "<a href='https://go.waymore.io/signup' target='blank' >Would you like to create an account in WayMore?</a>",
//                     value: "voice_createAccount",
//                     id: '13b'
//                 }
//             ]
//         },
//         {
//             id: uuidv4(),
//             title: `" Find <a href='https://docs.google.com/spreadsheets/d/1ZwlrgBqbVX1uXzlATgIY7sFJVvRaIYe-c7_pk5Kc_sM/edit#gid=1696475690' target="blank">Here</a> all the conditions`,
//             name: "findMore",
//             isDepended: true,
//             description: "",
//             dependedId: '13a',
//             type: 'submit',
//             options: []
//         },
//         {
//             id: uuidv4(),
//             title: `" Redirection to WayMore Registration page`,
//             name: "redirectToReg",
//             isDepended: true,
//             description: "",
//             dependedId: '13b',
//             type: 'submit',
//             options: []
//         },
//     ],
//     finalContent: ["Thanks ...",
//         "We are going to share with you a personalized quotation. Just to make sure that we will not validate GDPR"]
// }

export const Alphabets = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]