import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import './style.scss'

function FinalPage() {
  const location = useLocation();
  const [finalContent, setFinalContent] = useState([])

  useEffect(() => {
    setFinalContent(location?.state?.finalContent)
  }, [])

  return (
    <div className='final-page-container'>
      <div className='content'>
        {
          finalContent?.map((content) => {
            return <span>{content}</span>
          })
        }
      </div>
    </div>
  )
}

export default FinalPage