import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import './style.scss'
import Paper from "@material-ui/core/Paper";

const CustomPaper = (props) => {
    return (
        <Paper
            style={{ color: "#FFFF", background: "transparent", marginButtom: '10px', borderBottom: '2px dashed #FFFF' }}
            {...props}
        />
    );
};


function Dropdown({ handleOk, isCountry, mock, index, rightIcon, initialValues, setValue, maintainValues, setMaintainValues, countryArray, country, foundCountry, setCountry, setscrollDisable }) {

    let name = ''
    if (initialValues && initialValues[index - 1]) {
        name = initialValues[index - 1]
    }

    const getDefaultValue = () => {
        if (maintainValues[name]) {
            if (isCountry) {
                let defaultcountry = countryArray && countryArray?.find((countryArr) => {
                    return countryArr?.label === maintainValues[name]
                })
                return defaultcountry
            } else {
                const defaultOption = mock?.options?.find((moc) => moc?.label === maintainValues[name])
                return defaultOption
            }
        } else {
            if (isCountry && country) {
                let defaultcountry = countryArray && countryArray?.find((countryArr) => {
                    return countryArr?.value === country
                })
                return defaultcountry
            }
        }
    }

    useEffect(() => {
        if (isCountry && country) {
            setMaintainValues({ ...maintainValues, [`${name}`]: foundCountry?.length ? foundCountry[0]?.label : "" })
        }
    }, [])

    return (
        <div className='dropdown-container' onClick={() => (index)}>
            <div className='dropdown-title' >
                <h1 className='title'><span>{index}<img src={rightIcon} /></span>{mock.title}</h1>
            </div>
            <Autocomplete
                options={isCountry ? countryArray : mock?.options}
                getOptionLabel={(option) => option?.label || ""}
                onChange={(event, newValue) => {
                    setscrollDisable(false)
                    setValue(initialValues[index - 1], newValue?.value);
                    setMaintainValues({ ...maintainValues, [`${name}`]: isCountry ? newValue?.label : newValue?.value })
                    if (isCountry && newValue?.value) {
                        setCountry(newValue?.value)
                        handleOk(mock, name, true);
                    }
                }}
                renderInput={(params) => (
                    <TextField {...params} placeholder="Type or select an option" variant="standard" />
                )}
                onFocus={() => setscrollDisable(true)}
                onBlur={() => setscrollDisable(false)}
                defaultValue={getDefaultValue}
                getOptionSelected={(option, value) => { option.label === value.label }}
                PaperComponent={CustomPaper}
                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
            />
        </div>
    )
}

export default Dropdown