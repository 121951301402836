import { Button } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import tickIcon from '../../Assets/tick.svg'
import { Alphabets } from '../../Utilities/landing-mock-data'
import { useNavigate } from 'react-router-dom'
import './style.scss'
import { getCurrencyFromEuro } from '../../Utilities/helperFunction'

function SelectButton({ country, currency, mock, label, value, multiSelect, handleRadio, handleCheckbox, index, trueValue, isCostFormat, symbol, finalTotalCost, maintainValues, titleArray, isService }) {

    const navigate = useNavigate();
    const [costDetails, setCostDetails] = useState([]);
    const costDetailsRef = useRef(null);
    costDetailsRef.current = costDetails;

    const handleClick = (type) => {
        switch (type) {
            case "learnMore": {
                const transactionObj = {
                    event: 'clicked_booking',
                    transactionDetails: {
                        value: finalTotalCost,
                        currency: currency,
                    },
                    formData: maintainValues,
                    serviceCostDetails: costDetailsRef.current
                }
                if (window?.dataLayer) {
                    window.dataLayer = window.dataLayer || []
                    window.dataLayer.push(transactionObj);
                    window.parent.postMessage(transactionObj, 'https://www.waymore.io/')
                }
                routee.handleClickEvents({
                    userData: transactionObj,
                    event: transactionObj?.event,
                    routeeToken: "1f3af2c3-f67e-4377-ba7a-97590b4a4334"
                });
                navigate('/booking', { state: { country: country } })
                break;
            }
            case "createAccount": {
                const transactionObj = {
                    event: 'clicked_signup',
                    transactionDetails: {
                        value: finalTotalCost,
                        currency: currency,
                    },
                    formData: maintainValues,
                    serviceCostDetails: costDetailsRef.current
                }
                if (window?.dataLayer) {
                    window.dataLayer = window.dataLayer || []
                    window.dataLayer.push(transactionObj);
                    window.parent.postMessage(transactionObj, 'https://www.waymore.io/')
                }
                routee.handleClickEvents({
                    userData: transactionObj,
                    event: transactionObj?.event,
                    routeeToken: "1f3af2c3-f67e-4377-ba7a-97590b4a4334"

                });
                window.location.href = 'https://go.waymore.io/signup';
                break;
            }
            case "topup": {
                navigate('/captcha', { state: { amount: finalTotalCost, symbol: symbol, currency: currency, formData: maintainValues, serviceCostDetails: costDetailsRef.current } });
                break;
            }

        }
    }

    const getConvertedData = async () => {
        const tempObject = []
        for (const x of titleArray) {
            const totalCost = await getCurrencyFromEuro(currency, "EUR", Number(x.totalCost))
            const perCost = await getCurrencyFromEuro(currency, "EUR", Number(x.pricePerItem || x.pricePerThousand))
            const obj = { service: x.service, count: x.count, currency: 'EUR', totalCost: totalCost }
            if (x.pricePerItem) {
                obj['pricePerItem'] = perCost;
            }
            if (x.pricePerThousand) {
                obj["pricePerThousand"] = perCost
            }
            tempObject.push(obj);
        }
        setCostDetails([...tempObject])
    }
    useEffect(() => {
        getConvertedData()
    }, [titleArray])
    return (
        <>
            {multiSelect ?
                <><input type='checkbox' defaultChecked={trueValue?.includes(value)} className='check-input' value={value} onClick={(e) => handleCheckbox(e?.target?.value)} />
                    <div><Button className='select-button'><div className='labelIndex'>{Alphabets[index]}</div><span className='btnLabel'>{label}</span> <img src={tickIcon} /></Button></div></> : <>


                    {isCostFormat && !isService ? <>
                        <div><Button onClick={() => handleClick(value)} className='cost-button'>
                            <div className='labelIndex'>{Alphabets[index]}</div><span className='btnLabel' dangerouslySetInnerHTML={{ __html: label }}></span></Button></div></>
                        :
                        <><input className='radio-input' checked={trueValue === value} type="radio" name={mock?.name} value={value} onChange={(e) => handleRadio(e.target.value)} />
                            <div><Button className='select-button'>
                                <div className='labelIndex'>{Alphabets[index]}</div><span className='btnLabel' dangerouslySetInnerHTML={{ __html: label }}></span> <img src={tickIcon} /></Button></div></>}
                </>}
        </>
    )
}


export default SelectButton