import { useRef } from 'react'
import './style.scss'
import ReCAPTCHA from 'react-google-recaptcha'
import { verifyCaptcha } from '../../API/api';
import { useLocation, useNavigate } from 'react-router-dom';

function CaptchaPage() {
  const captchaRef = useRef(null)
  const { state } = useLocation()
  const navigate = useNavigate()
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = captchaRef?.current?.getValue();
    const captchaDetails = await verifyCaptcha(token)
    if (captchaDetails?.data?.data?.success) {
      navigate('/payment', { state: { ...state, ReCAPTCHA: true } })
      captchaRef?.current?.reset();
    }
    else {
      captchaRef?.current?.reset();
    }
  }

  return (
    <div className='captcha-page-container'>
      <div className='content'>
        <form onSubmit={handleSubmit} >
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            ref={captchaRef}
          />
          <button>Submit</button>
        </form>
      </div>
    </div>
  )
}

export default CaptchaPage