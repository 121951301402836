import React, { useState } from 'react'
import SelectButton from '../Select-button'
import './style.scss'


function CheckBox({ addNextData, mock, index, rightIcon, initialValues, setValue, maintainValues, setMaintainValues, titleArray }) {
    const [checkedArray, setCheckedArray] = useState([])
    let name = ''
    if (initialValues && initialValues[index - 1]) {
        name = initialValues[index - 1]
    }


    const handleChecked = (e) => {
        let checkedArr = maintainValues[name] ? [...maintainValues[name]] : [...checkedArray]

        if (checkedArr.includes(e)) {
            checkedArr = checkedArr.filter((data) => data !== e)
        } else {
            checkedArr.push(e)
        }
        setValue(initialValues[index - 1], checkedArr)
        setMaintainValues({ ...maintainValues, [`${name}`]: checkedArr })
        setCheckedArray(checkedArr)
        addNextData(checkedArr, index, mock?._id, false)
    }
    return (
        <div onClick={() => (index)} className='checkbox-container'>
            <div className='checkbox-title'>
                <h1 className='title'><span>{index}<img src={rightIcon} /></span>{mock?.title}</h1>
            </div>
            <div className='checkbox-button-container'>
                <div className='select-many'>Choose as many as you like</div>
                {mock?.options?.map((moc, i) =>
                    <div className='checkbox-button'>
                        <SelectButton country={maintainValues?.country} label={moc?.label} value={moc?.value} multiSelect={true} handleCheckbox={(e) => handleChecked(e)} trueValue={maintainValues[name]} mock={mock} index={i} maintainValues={maintainValues} titleArray={titleArray} />

                    </div>
                )}
            </div>
        </div>
    )
}

export default CheckBox