import React, { useEffect, useState } from 'react';
import './style.scss';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import TextField from '@material-ui/core/TextField';

function TransactionSuccess({formData, serviceCostDetails, convertedAmount ,transactionID }) {
    const [open, setOpen] = useState(false)

    const handleLoginRegister = () => {
        window.location.href = 'https://go.waymore.io/signup';
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(transactionID).then(() => setOpen(true))
    }
    useEffect(()=>{
       const transactionObj = {
            event: 'payment_success',
            send_to: 'AW-10932421562/w7EeCOqA4IgYELqH_two',
            transactionDetails: {
                value: convertedAmount,
                currency: 'EUR',
                transaction_id: transactionID
            },
            formData,
            serviceCostDetails
        }
        window.parent.postMessage(transactionObj, 'https://www.waymore.io/');
        routee.handleClickEvents({
            userData: transactionObj,
            event: transactionObj?.event,
            routeeToken: "1f3af2c3-f67e-4377-ba7a-97590b4a4334"
        });
    },[])

    return (
        <div className='transactionSuccess-container' id='quote-transaction-success'>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={() => setOpen(false)} severity="success">
                    Copied successfully
                </Alert>
            </Snackbar>
            <div className='transactionid-header' id="transactionid-header">
                Your Transaction ID is <span><span id='transactionId'>{transactionID}</span> <ContentCopyIcon onClick={handleCopy} /></span><span className='click-copy'>Click to copy</span>
            </div>
            <div className='inputField' id="inputField">
                <div>Enter your E-mail address to receive your Token via E-mail.</div>
                <TextField placeholder='example@gmail.com' />
            </div>
            <div className='login-text' id="login-text">
                Please keep this token, and share it to our customer success department to topup your account.
            </div>
            <button onClick={handleLoginRegister} > Register/Login </button>

        </div>
    )
}

export default TransactionSuccess