import React, { useEffect, useState, useRef, useContext } from 'react'
import Input from '../../Components/Input'
import RadioButton from '../../Components/RadioButton'
import CheckBox from '../../Components/Checkbox'
import Dropdown from '../../Components/Dropdown'
import debounce from 'lodash.debounce'
import './style.scss'
import rightarrowIcon from '../../Assets/rightarrowIcon.svg'
import TickIcon from '../../Assets/tick.svg'
import { useForm } from "react-hook-form";
import Button from '../../Components/Button';
import * as _ from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getContentData, getCountry, getFormData, getFormDataSorted, getIndustryIdentifier, getInitialFormData, getOptionalFormData, getServicePrice, waymoreEvents } from '../../API/api'
import { CircularProgress } from '@mui/material';
import CostFormat from '../../Components/CostFormat'
import { countries } from 'country-list-json';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import appContext from '../../appContext'
import { nonServiceNames } from '../../Utilities/Constant'
import { getCurrencyFromEuro } from '../../Utilities/helperFunction'

function QuoteForm() {
    const { handleSubmit, setValue } = useForm();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { isService } = useContext(appContext)
    const [isValidUrl, setValidUrl] = useState(true)
    const [country, setCountry] = useState("")
    const [serviceDetails, setServiceDetails] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [finalContent, setFinalContent] = useState([])
    const [btnLoading, setBtnLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [initialValues, setInitialValues] = useState([])
    const [maintainValues, setMaintainValues] = useState({})
    const [realData, setRealData] = useState([])
    const [scrollCapture, setScrollCapture] = useState('down')
    const [pageIndex, setPageIndex] = useState(0)
    const [costFormScroll, setCostFormScroll] = useState(0)
    const [isDownScrolled, setIsDownScrolled] = useState(true)
    const [category, setCategory] = useState([])
    const [formData, setFormData] = useState([])
    const [countryArray, setCountryArray] = useState([])
    const [foundCountry, setFoundCountry] = useState("")
    const [expertText, setExpertText] = useState("")
    const [currency, setCurrency] = useState("");
    const [titleArray, setTitleArray] = useState([]);
    const [scrollDisable, setscrollDisable] = useState(false)
    const scrollDisableRef = useRef(false)
    scrollDisableRef.current = scrollDisable

    const onSubmit = async (data, e) => {
        const finalData = {}
        realData.map((real) => {
            if (real?.type === 'input') {
                real?.options?.map((rel) => {
                    finalData[rel?.name] = maintainValues[rel?.name]
                })
            } else if (real?.type !== 'submit') {
                finalData[real?.name] = maintainValues[real?.name]
            }
        })

        const res = await waymoreEvents(finalData)
        setBtnLoading(true)
        navigate('/submitted', { state: { finalContent: finalContent, formData: formData } })
        setBtnLoading(false)
    };
    const buttonRef = useRef()

    const pageIndexRef = useRef(pageIndex);
    pageIndexRef.current = pageIndex;
    const realDataRef = useRef(realData)
    realDataRef.current = realData
    const maintainValueRef = useRef(maintainValues)
    maintainValueRef.current = maintainValues
    const costFormScrollRef = useRef(costFormScroll)
    costFormScrollRef.current = costFormScroll
    const isDownScrolledRef = useRef(isDownScrolled)
    isDownScrolledRef.current = isDownScrolled


    const prepareInitialValues = () => {
        let initialArray = []
        if (realData && realData.length) {

            realData.map((mock, index) => {
                if (mock.type === 'input') {
                    let newArr = []
                    mock.options.map((moc) => {
                        newArr.push(moc?.name)
                    })
                    initialArray[index] = newArr
                } else if (mock.type !== 'submit') {
                    initialArray[index] = mock?.name
                }
            })
            setInitialValues(initialArray)
        }
    }

    const handleOk = async (mock, name, hasValue) => {

        if (mock.type === "input") {
            for (let moc of mock?.options) {

                if (moc?.type === "url" && maintainValueRef.current[moc?.name]) {
                    var expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi
                    var regex = new RegExp(expression)
                    if (maintainValueRef.current[name]?.match(regex)) {
                        const res = await getIndustryIdentifier(maintainValueRef.current[name])
                        if (res.status === 200 && res?.data?.data?.data?.domain?.categories) {
                            setCategory(res?.data?.data?.data?.domain?.categories);
                        } else {
                            setCategory([])
                        }
                        setValidUrl(true)
                        setScrollCapture("down")
                        setPageIndex(pageIndexRef?.current + 1)
                    } else {
                        setValidUrl(false)
                        setTimeout(() => {
                            setValidUrl(true)
                        }, 3000)
                    }
                } else {
                    if (moc.isRequired) {
                        if (maintainValueRef.current[moc?.name]) {
                            setScrollCapture("down")
                            setPageIndex(pageIndexRef?.current + 1)
                        } else {
                            setOpen(true)
                            setErrMsg(`${moc?.label ? moc?.label : 'This field'} is required`)
                            break;
                        }

                    } else {
                        setScrollCapture("down")
                        setPageIndex(pageIndexRef?.current + 1)
                    }
                }
            }
        } else {
            if (maintainValueRef?.current[mock?.name]?.length || hasValue) {
                setScrollCapture("down")
                setPageIndex(pageIndexRef?.current + 1)
            } else {
                setOpen(true)
                setErrMsg("This Field is required")
            }
        }
    }

    const handleDontKnow = async (mock, index) => {
        let cloneMaintain = { ...maintainValues }

        setMaintainValues({ ...cloneMaintain, [`${initialValues[index]}`]: "" })

        let cloneData = [...realData]
        let res = await getOptionalFormData({ idArray: mock?.dependedId })
        if (res?.status === 200 && res?.data?.data?.length) {
            const data = res?.data?.data
            const currentDependencies = realData.filter((rel) => rel?.dependedId === mock?.dependedId)
            let neededData = []
            currentDependencies.map((current) => {
                if (!mock?.modifyOrder?.removeOrder.includes(current.order)) {
                    neededData.push(current)
                }
            })
            let newData = [...data, ...neededData]
            cloneData.splice(index, currentDependencies.length, ...newData)
            setRealData(cloneData)
        }
    }

    const addInitialData = async () => {
        setIsLoading(true)
        const formDataRes = await getInitialFormData()
        let formData = [];
        if (formDataRes?.status === 200) {
            const mode = searchParams.get("mode") || "";
            if (isService || mode === "service") {
                formData = formDataRes?.data?.data?.filter((eachForm) => !nonServiceNames.includes(eachForm.name))
            } else {
                formData = formDataRes?.data?.data
            }
            setFormData(formData)
        }
        const finalRes = await getContentData()
        if (finalRes.status === 200) {
            setFinalContent(finalRes?.data?.data[0]?.frontPageContent?.finalContent)
        }
        let res = await getCountry()
        if (res?.status === 200) {
            if (res?.data) {
                let str = res?.data;
                str = str?.replace('geoip(', '')?.replace(")", "")
                str = JSON.parse(str || null)
                setCountry(str?.country_code)
            }
        }
        if (realData && !realData?.length) {
            setRealData(formData)
        }
        setIsLoading(false)
        let countryArr = []
        countries && countries.map((count) => {
            countryArr.push({
                label: count?.name,
                value: count?.code
            })
        })
        setCountryArray(countryArr)
    }

    const addNextData = async (key, index, ownId, isRadio) => {
        const selfIndex = _.findIndex(formData, function (findId) { return findId?._id === ownId })
        if (isRadio) {
            const nextID = formData[selfIndex]?.options?.filter((opt) => opt?.value === key)

            const id = nextID && nextID.length && nextID[0]?.id ? nextID[0]?.id : 0
            const nextDropRes = await getFormData({ dependedId: id })
            let nextDrop = []
            if (nextDropRes?.status === 200 && nextDropRes?.data?.data?.length) {
                nextDrop = nextDropRes?.data?.data
            }
            let cloneData = [...realData]
            const removeID = formData[selfIndex]?.options?.filter((opt) => opt?.value !== key)
            removeID?.map((nex) => {
                cloneData = cloneData.filter((clone) => {
                    return clone?.isDepended ? clone?.dependedId !== nex?.id : clone
                })
            })
            const splicedData = cloneData.splice(index)
            cloneData.push(...nextDrop)
            cloneData = [...cloneData, ...splicedData]
            setRealData([...cloneData])

        } else {
            const nextID = formData[selfIndex]?.options?.filter((opt) => key?.includes(opt?.value))
            let idArray = [];
            nextID?.map((id) => idArray.push(id?.id))
            const nextDropRes = await getFormDataSorted({ idArray })
            let nextDrop = []
            if (nextDropRes?.status === 200 && nextDropRes?.data?.data?.length) {
                nextDrop = nextDropRes?.data?.data
            }
            if (nextDrop && nextDrop?.length) {
                let cloneData = [...realData]
                let splicedData = cloneData.splice(index)
                formData[selfIndex]?.options?.map((self) => {
                    splicedData = splicedData.filter((data) => self?.id !== data?.dependedId)
                })
                cloneData.push(...nextDrop)
                cloneData = [...cloneData, ...splicedData]
                _.uniqWith(cloneData, _.isEqual)

                setRealData([...cloneData])
            }
            else {
                let cloneData = [...realData]
                formData[selfIndex]?.options?.map((self) => {
                    cloneData = cloneData.filter((data) => self?.id !== data?.dependedId)
                })
                setRealData(cloneData)
            }
        }
    }

    const getService = async () => {
        setIsLoading(true)
        let serviceRes = await getServicePrice(country)
        if (serviceRes?.status === 200) {
            const serviceData = serviceRes?.data?.data
            setServiceDetails(serviceData)
            setCurrency(serviceData?.country?.currency)
        }
        const countryData = countryArray && countryArray.filter((count) => {
            return count?.value === country
        })
        setFoundCountry(countryData)
        setIsLoading(false)
    }

    const handleServiceSubmit = async () => {
        const equalentValue = await getCurrencyFromEuro(currency, "EUR", sessionStorage.getItem('totalCost'))
        window.parent.postMessage({ selection: 'submit', amount: equalentValue?.toFixed(2), currency: "eur" }, '*')
        sessionStorage.clear()
    }

    const handleServiceCancel = () => {
        window.parent.postMessage({ selection: 'cancel' }, '*')
        sessionStorage.clear()
    }

    useEffect(() => {
        addInitialData()
    }, [])

    useEffect(() => {
        prepareInitialValues()
    }, [realData])

    useEffect(() => {
        if (country) {
            getService()
        }
    }, [country])

    useEffect(() => {
        /* eslint-disable-next-line no-restricted-globals */
        window.addEventListener('wheel', debounce((e) => {
            let newPageIndex = pageIndexRef.current;
            if (newPageIndex < realDataRef?.current?.length - 1 || e.deltaY < 0) {
                if (e.deltaY > 0 && !scrollDisableRef.current) {
                    if (isDownScrolledRef.current || realDataRef?.current[newPageIndex].name !== "costFormat") {
                        handleOk(realDataRef?.current[pageIndexRef?.current], realDataRef?.current[pageIndexRef?.current]?.name)
                    }

                }
                else if (newPageIndex > 0 && !scrollDisableRef.current) {
                    if (costFormScrollRef.current === 0 || isDownScrolledRef.current) {
                        setScrollCapture('up')
                        setPageIndex((pre) => pre - 1)
                    }
                }
            }
        }, 200))
        return (() => {
            window.removeEventListener('wheel', () => { })
        })
    }, [])

    useEffect(() => {
        /* eslint-disable-next-line no-restricted-globals */
        let div = document.getElementsByClassName("each-card")
        if (div && div.length) {
            div[0]?.addEventListener('scroll', (e) => {
                setCostFormScroll(e?.srcElement?.scrollTop)
            })
        }
        return (() => {
            if (div && div.length) {
                div[0]?.removeEventListener('scroll', () => { })
            }
        })

    })
    useEffect(() => {
        /* eslint-disable-next-line no-restricted-globals */
        let element = document.querySelector(".each-card");
        element?.addEventListener('scroll', function (event) {
            var element = event.target;
            if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                setIsDownScrolled(true)
            } else {
                setIsDownScrolled(false)
            }
        });

        return (() => {
            element?.removeEventListener('scroll', () => { })
        })

    })

    useEffect(() => {
        /* eslint-disable-next-line no-restricted-globals */
        window.addEventListener('keydown', (e) => {
            if (e.key === "Enter" && pageIndexRef?.current < realDataRef?.current?.length - 1)
                handleOk(realDataRef?.current[pageIndexRef?.current], realDataRef?.current[pageIndexRef?.current]?.name)
        })
        return (() => {
            window.removeEventListener('keydown', () => { })
        })
    }, [])


    return (
        <div className='form-container' id='form-container'>
            {isLoading ? <CircularProgress style={{ 'color': 'white' }} /> :
                <form className='form' id='form' onSubmit={handleSubmit(onSubmit)}>
                    {realData && realData?.length ? realData?.map((mock, index) => {

                        if (index === pageIndex) {

                            return <div className={`each-card ${index} ${scrollCapture}`} >
                                {mock.name === "costFormat" ? <CostFormat currency={currency} expertText={expertText} addNextData={addNextData} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} serviceDetails={serviceDetails} titleArray={titleArray} setTitleArray={setTitleArray} /> :
                                    mock?.type === "country" ? <Dropdown setscrollDisable={setscrollDisable} handleOk={handleOk} countryArray={countryArray} country={country} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} isCountry={true} foundCountry={foundCountry} setCountry={setCountry} /> :
                                        mock.type === "input" ? <Input setExpertText={setExpertText} isValidUrl={isValidUrl} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} category={category} /> :
                                            mock.type === "select" ? <RadioButton addNextData={addNextData} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} serviceDetails={serviceDetails} titleArray={titleArray} /> :
                                                mock.type === "multi-select" ? <CheckBox addNextData={addNextData} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} titleArray={titleArray} /> :
                                                    mock.type === "dropdown" ? <Dropdown setscrollDisable={setscrollDisable} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} isCountry={false} /> :
                                                        mock.type === "submit" ? <h1 className='Submit'><span>{index + 1}<img src={rightarrowIcon} /></span><div dangerouslySetInnerHTML={{ __html: mock?.title }}></div></h1> :
                                                            <></>}
                                {index === realData?.length - 1 ? <div className='service-button'><Button text="Submit" type={isService ? 'button' : 'submit'} btnLoading={btnLoading} onClick={isService ? () => { handleServiceSubmit() } : () => { }} /> {isService && <Button text="Cancel" type='button' onClick={handleServiceCancel} />}</div> : <div className='btnSpan'><Button text='OK' onClick={() => handleOk(mock, mock?.name)} buttonRef={buttonRef} type="button" icon={TickIcon} /> {mock?.isDontKnow ?
                                    <Button text="I Don't know" type="button" onClick={() => handleDontKnow(mock, index)} /> : <span>Press Enter</span>} </div>}



                            </div>
                        }
                    }) : <></>}
                </form>}
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={() => { setOpen(false), setErrMsg("") }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={() => { setOpen(false); setErrMsg("") }} severity="error">
                    {errMsg}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default QuoteForm