import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton';
import './style.scss'

function Button({ text, icon, onClick, type, btnLoading, buttonRef }) {

    return (
        type === "submit" ? <LoadingButton loading={btnLoading} type={type} variant="outlined" className='loadingBtn'> {btnLoading ? "" : "Submit"} </LoadingButton> : <button type={type} ref={buttonRef} className={`${text === 'Cancel' ? 'service-cancel' : ""}`} onClick={onClick}><span>{text}{icon ? <img src={icon} /> : <></>}</span></button>
    )
}

export default Button;