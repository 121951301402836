import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

export default function AlertDialog({ open, cameraPermission, locationPermission }) {
    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {locationPermission && !cameraPermission ? <DialogContent id="alert-dialog-description">
                    <p>To proceed to a payment, you should have camera activated.</p>
                    <p> Click <a target="_blank" href={"https://www.waymore.io/how-do-i-enable-the-webcam/"}> here </a> to see how to enable camera</p>
                </DialogContent> : cameraPermission && !locationPermission ? <DialogContent id="alert-dialog-description">
                    <p>To proceed to a payment, you should have location sharing activated.</p>
                    <p> Click <a target="_blank" href={"https://docs.buddypunch.com/en/articles/919258-how-to-enable-location-services-for-chrome-safari-edge-and-android-ios-devices-gps-setting"}> here </a> to see how to enable location</p>
                </DialogContent> : <DialogContent id="alert-dialog-description">
                    <p>To proceed to a payment, you should have location sharing and camera activated.</p>
                    <p> Click <a target="_blank" href={"https://docs.buddypunch.com/en/articles/919258-how-to-enable-location-services-for-chrome-safari-edge-and-android-ios-devices-gps-setting"}> here </a> to see how to enable location</p>
                    <p> Click <a target="_blank" href={"https://www.waymore.io/how-do-i-enable-the-webcam/"}> here </a> to see how to enable camera</p>
                </DialogContent>}
            </Dialog>
        </div>
    );
}
