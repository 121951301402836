import { useContext, useEffect } from "react";
import { Routes, Route, useSearchParams, useNavigate } from "react-router-dom";
import { getContentData } from "../../API/api";
import LandingPage from "../LandingPage";
import Appointment from "../Appointment";
import FinalPage from "../FinalPage";
import Payment from "../Payment";
import QuoteForm from "../QuoteForm";
import CaptchaPage from "../Captcha";
import appContext from "../../appContext";

const RootRoutes = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setContent, setAppointmentIdDetails, setIsService } =
    useContext(appContext);

  const getContent = async () => {
    const res = await getContentData();
    if (res.status === 200) {
      setContent(res?.data?.data[0]?.style);
      setAppointmentIdDetails(res?.data?.data[0]?.appointmentID);
    }
  };

  useEffect(() => {
    const mode = searchParams.get("mode") || "";
    if (mode === "service") {
      setIsService(true);
      navigate("/quote?mode=service");
    }
    getContent();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/appointment" element={<Appointment />} />
      <Route path="/final" element={<FinalPage />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/quote" element={<QuoteForm />} />
      <Route path="/captcha" element={<CaptchaPage />} />
    </Routes>
  );
};

export default RootRoutes;
