import React, { useContext, useEffect, useState } from 'react'
import { getEmailService, getFacebookMessengerService, getMinusPercent, getPushService, getSmsService, getTelegramService, getViberService, getVoiceService, getWhatsappService } from '../../Utilities/helperFunction'
import './style.scss'
import RadioButton from '../RadioButton'
import { Input } from '@mui/material';
import exportIcon from '../../Assets/exportIcon.svg'
import appContext from '../../appContext';

function CostFormat({ currency, addNextData, mock, index, rightIcon, initialValues, setValue, maintainValues, setMaintainValues, serviceDetails, expertText, titleArray, setTitleArray }) {
    const [finalTotalCost, setFinalTotalCost] = useState(0);
    const [symbol, setSymbol] = useState("");
    const [minusPercent, setMinusPercent] = useState("");
    const [newTitle, setNewTitle] = useState("");
    const [viberMinimumCost, setViberMinimumCost] = useState("")
    const { isService } = useContext(appContext)

    const handleCostFormat = async () => {
        let titleArr = []

        if (maintainValues?.services?.includes("sms")) {

            let smsService = serviceDetails?.services?.filter((serv) => serv?.name === 'Discount Policy')
            smsService = smsService && smsService?.length && smsService[0]?.subServices;
            smsService = smsService && smsService?.length && smsService[0]?.headers;

            if (maintainValues["smsCount"]) {

                const number = Number(maintainValues["smsCount"]);
                titleArr.push(getSmsService(maintainValues, smsService, mock?.title, number, "Sms", currency))
            }

        }
        if (maintainValues?.services?.includes("email")) {

            let emailService = serviceDetails?.services?.filter((serv) => serv?.name === "Email")
            emailService = emailService && emailService?.length && emailService[0]?.subServices;

            if (maintainValues["emailInDatabaseCount"]) {

                const number = Number(maintainValues["emailInDatabaseCount"]);
                emailService = emailService && emailService?.length && emailService[1]?.headers;
                titleArr.push(getEmailService(emailService, number, "Email", currency, false))

            } else if (maintainValues["emailPlannedCount"]) {

                emailService = emailService && emailService?.length && emailService[2]?.headers;
                const number = Number(maintainValues["emailPlannedCount"]);
                titleArr.push(getEmailService(emailService, number, "Email", currency, true))

            }
        }
        if (maintainValues?.services?.includes("pushNotifications")) {

            let pushService = serviceDetails?.services?.filter((serv) => serv?.name === "Push Notification")
            pushService = pushService && pushService?.length && pushService[0]?.subServices;
            pushService = pushService && pushService?.length && pushService.filter((push) => push?.name?.trim() === "Push Monthly Plan Per Subscriber")
            pushService = pushService && pushService?.length && pushService[0]?.headers
            pushService = pushService && pushService?.length && pushService[1]?.rowElements;

            if (maintainValues["pushNotificationCount"]) {

                const number = Number(maintainValues["pushNotificationCount"]);
                titleArr.push(getPushService(pushService, number, "Push Notification", currency))
            }

        }
        if (maintainValues?.services?.includes("facebookMessenger")) {

            const messagingApps = serviceDetails?.services?.filter((serv) => serv?.name === "Messaging Apps")
            let facebookService = messagingApps?.length && messagingApps[0]?.subServices?.filter((serv) => serv?.name === "Fb Messenger")
            facebookService = facebookService?.length && facebookService[0]?.headers;

            if (maintainValues["optedInUsersCount"]) {
                const number = Number(maintainValues["optedInUsersCount"]);
                titleArr.push(getFacebookMessengerService(facebookService, number, "Facebook Messenger", currency))
            }
        }
        if (maintainValues?.services?.includes("viber")) {

            const messagingApps = serviceDetails?.services?.filter((serv) => serv?.name === "Messaging Apps")
            let viberService = messagingApps?.length && messagingApps[0]?.subServices?.filter((serv) => serv?.name === "Viber Plan 1")
            viberService = viberService?.length && viberService[0]?.headers;
            let minMonthlycost = viberService?.filter((vibe) => vibe?.name?.toLowerCase()?.trim() === "Monthly Cost per SenderID"?.toLowerCase())
            minMonthlycost = minMonthlycost?.length && minMonthlycost[0]?.rowElements[0]?.price
            setViberMinimumCost(minMonthlycost)
            viberService = viberService?.length && viberService[3]?.rowElements;

            if (maintainValues["viberMessageCount"]) {
                const number = Number(maintainValues["viberMessageCount"])
                titleArr.push(getViberService(viberService, number, "Viber", currency))
            }
        }
        if (maintainValues?.services?.includes("whatsapp")) {
            if (maintainValues["whatsappMessageCount"]) {
                const number = Number(maintainValues["whatsappMessageCount"])

                titleArr.push(await getWhatsappService(number, "Whatsapp", currency))
            }
        }
        if (maintainValues?.services?.includes("telegram")) {

            const messagingApps = serviceDetails?.services?.filter((serv) => serv?.name === "Messaging Apps")
            let telegramService = messagingApps?.length && messagingApps[0]?.subServices?.filter((serv) => serv?.name === "Viber Plan 1")
            telegramService = telegramService?.length && telegramService[0]?.headers;
            telegramService = telegramService?.length && telegramService[3]?.rowElements;
            if (maintainValues["telegramMessageCount"]) {
                const number = Number(maintainValues["telegramMessageCount"])
                titleArr.push(getTelegramService(telegramService, number, "Telegram", currency))
            }

        }
        if (maintainValues?.services?.includes("voice")) {

            let voiceService = serviceDetails?.services?.filter((serv) => serv?.name === "Voice")
            voiceService = voiceService && voiceService?.length && voiceService[0]?.subServices;
            voiceService = voiceService && voiceService?.length && voiceService[0]?.headers;

            if (maintainValues["voiceMessageCount"]) {
                const number = Number(maintainValues["voiceMessageCount"]);
                titleArr.push(...getVoiceService(voiceService, number, "Voice", currency, maintainValues))
            }

        }
        if (titleArr && titleArr?.length) {
            for (let sym of titleArr) {
                if (sym?.symbol) {
                    setSymbol(sym?.symbol)
                    break;
                }
            }
        }
        setTitleArray(titleArr)
        let cost = 0
        titleArr && titleArr.map((title) => {
            cost += Number(title?.totalCost)
        })
        setFinalTotalCost(cost.toFixed(2))
        sessionStorage.setItem('totalCost', cost.toFixed(2))
        const newPercent = getMinusPercent(cost)

        setMinusPercent(newPercent)
    }

    const getNewTitle = () => {
        if (expertText && maintainValues?.websiteLink && maintainValues?.isWebsite === "yes") {
            setNewTitle(`WayMore is an expert in the field of ${expertText} and helps companies to increase sales and reduces costs.`)
        } else if (!expertText && maintainValues?.websiteLink && maintainValues?.isWebsite === "yes") {
            setNewTitle(`WayMore is an expert and can help companies like ${maintainValues?.websiteLink} to increase sales and reduces costs.`)
        } else {
            setNewTitle("WayMore offers free of charge the landing page builder and you can have your own website within 5 minutes from now completely free of charge, using our ready made high quality templates.")
        }
    }

    useEffect(() => {
        handleCostFormat()
        getNewTitle()
        if (isService) {
            mock.options = []
        }
    }, [])

    return (
        <div className='costFormat-container'>
            <div className='cost-container'>
                <h1 className='costprice-title'>{newTitle}</h1>
                <div className='costFormat-service'>
                    {titleArray && titleArray?.map((title) => {
                        return <div className='costComponent-container'>
                            <div className='costComponent-head'>
                                {title?.service}<img height="18px" src={title?.icon} />
                            </div>
                            <div className='costComponent-content'>
                                <div className='head-count'>{title?.count}</div>
                                <span className='head-service'>{title?.footName}</span>
                                {(title?.pricePerItem || title?.pricePerThousand)
                                    ? <div>{title?.pricePerItem ?
                                        <div className='content-data'>
                                            <span>{">"} <span className='price'>{title?.pricePerItem}{title?.pricePerItem ? title?.symbol : ""}{" "} Price Per Item <b> = {title?.totalCost} {title?.symbol} </b></span></span>
                                        </div> : <div className='content-data'>
                                            <span>{">"} <span className='price'>{title?.pricePerThousand}{title?.symbol} Price Per Thousand <b> = {title?.totalCost} {title?.symbol} </b>  </span></span>
                                        </div>}</div> : <div className='price-equalto'>
                                        = {title?.totalCost} {title?.symbol} </div>}
                            </div>
                        </div>
                    })}
                </div>
            </div>
            {titleArray && <div className='totalCost'>
                <div className='cost'>Total cost for all the services above: </div>
                <div className='sub-cost' id="total-cost">{finalTotalCost}<span className='symbol'>{symbol}</span></div>
            </div>}
            <div className='export-quote'> <span><img height="40px" src={exportIcon} />Export your quote</span><Input placeholder='Enter your email' /></div>
            <div className='costFormat-options'>
                <RadioButton currency={currency} viberMinimumCost={viberMinimumCost} addNextData={addNextData} mock={mock} index={index} rightIcon={rightIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} serviceDetails={serviceDetails} symbol={symbol} finalTotalCost={finalTotalCost} minusPercent={minusPercent} isCostFormat={true} titleArray={titleArray} isService={isService} />
            </div>
        </div>
    )
}

export default CostFormat