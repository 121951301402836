import React from "react";

const appState = {
    appointmentIdDetails: {},
    setAppointmentIdDetails: (appointmentIdDetails) => { },
    isService: false,
    setIsService: (isService) => { },
    content: [],
    setContent: (content) => { }

}

export const appContext = React.createContext(appState);


export default appContext;