import React, { useEffect, useRef, useState } from 'react'
import timeicon from '../../Assets/clock-icon.png';
import { useNavigate } from 'react-router-dom'
import './style.scss'
import emailIcon from '../../Assets/email_image.png'
import omnichannelIcon from '../../Assets/omnichannel_image.png'
import pushIcon from '../../Assets/push_image.png'
import appointmentIcon from '../../Assets/appointmentIcon.svg'
import apiIcon from '../../Assets/API.png'
import FormIcon from '../../Assets/Form.png'
import landingPageIcon from '../../Assets/landingPageIcon.png'
import { getContentData } from '../../API/api';
function LandingPage() {
    const communicationService = ['Push Notification', 'Email Marketing']
    const [frontPageContent, setFrontPageContent] = useState([])
    const [softwareModules, setSoftwareModules] = useState([])
    const [communicationModules, setCommunicationModules] = useState([])
    const navigate = useNavigate()
    const buttonRef = useRef()
    const getContent = async () => {
        const res = await getContentData()
        if (res.status === 200) {
            setFrontPageContent(res?.data?.data[0]?.frontPageContent)
            let services = res?.data?.data[0]?.frontPageContent?.services
            let softwareArr = []
            let communicationArr = []
            services.map((service) => {
                if (communicationService.includes(service?.title?.trim())) {
                    communicationArr.push(service)
                } else {
                    softwareArr.push(service)
                }
            })
            setSoftwareModules(softwareArr)
            setCommunicationModules(communicationArr)
        }
    }
    const getIcons = (index, type) => {

        if (type === 'software') {
            switch (index) {
                case 0: {
                    return omnichannelIcon
                }
                case 1: {
                    return FormIcon
                }
                case 2: {
                    return appointmentIcon
                }
                case 3: {
                    return apiIcon
                }
                case 4: {
                    return landingPageIcon
                }
            }
        } else {
            switch (index) {
                case 0: {
                    return emailIcon
                }
                case 1: {
                    return pushIcon
                }
            }
        }
    }

    const handleGetQuote = () => {
        window.parent.postMessage({ event: "request_quote", data: "requested" }, 'https://www.waymore.io');
        navigate('/quote')
    }

    useEffect(() => {
        getContent()
    }, [])

    useEffect(() => {
        setTimeout(() => {
            buttonRef.current.focus()
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }, 1000)
    }, [])

    return (
        <div className='container' id='main-container' onClick={() => buttonRef.current.focus()}>
            <div className='header'>
                {frontPageContent?.topContent?.map((content) => <><span dangerouslySetInnerHTML={{ __html: content }}></span></>
                )}
                <div className='content'>
                    <span>SOFTWARE MODULES</span>
                    <div className='card-container'>
                        {softwareModules?.map((content, index) => <a className='service-container' href={content?.link} target="_blank">
                            <div className='topDiv'>
                                <span><img src={getIcons(index, 'software')}></img></span>
                                <span>{content?.title}</span>
                            </div>
                            <span className='bottom-content'>{content?.content}</span>

                        </a>
                        )}
                    </div>
                    <span>COMMUNICATION CHANNELS</span>
                    <div className='card-container-com'>
                        {communicationModules?.map((content, index) => <a className='service-container' href={content?.link} target="_blank">
                            <div className='topDiv'>
                                <span><img src={getIcons(index, 'communication')}></img></span>
                                <span>{content?.title}</span>
                            </div>
                            <span className='bottom-content'>{content?.content}</span>

                        </a>
                        )}
                    </div>
                </div>
            </div>
            <div className='end-content'>{frontPageContent?.endContent}</div>
            <div className='footer'>
                <div className="time"><img src={timeicon} />{frontPageContent?.footer?.time?.text}</div>
                <div><button ref={buttonRef} className='get-quote' id='get-quote' onClick={handleGetQuote}>{frontPageContent?.footer?.button?.text}</button> <span>{frontPageContent?.footer?.enterText}</span></div>
            </div>

        </div>
    )
}

export default LandingPage