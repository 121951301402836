import RootRoutes from "./Pages/Routing/routes";
import "./App.css";
import { useState } from "react";
import appContext from './appContext';
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  const [content, setContent] = useState([])
  const [appointmentIdDetails, setAppointmentIdDetails] = useState({})
  const [isService, setIsService] = useState(false)


  const contextItems = {
    appointmentIdDetails,
    setAppointmentIdDetails,
    isService,
    setIsService,
    content,
    setContent
  }


  return (
    <div className="App" id="App" style={{
      '--color': content?.foregroundColor, background: `url(${content?.background})`, '--btn_color': content?.buttonColor, '--btn_txt_clr': content?.buttonTextColor, '--font_family': content?.fontFamily,
      '--landing_btn_color': content?.landingPageButtonColor, '--landing_btn_txt_color': content?.landingPageTxtColor, '--loaderColor': content?.loaderColor
    }}>
      <appContext.Provider value={contextItems}>
        <Router>
          <RootRoutes />
        </Router>
      </appContext.Provider>
    </div>
  );
}

export default App;
