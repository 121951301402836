import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { useLocation } from "react-router-dom";
import appContext from "../../appContext";

function Appointment() {
    const [appointmentId, setAppointmentId] = useState()
    const { appointmentIdDetails } = useContext(appContext);
    let location = useLocation();

    useEffect(() => {
        let locData = location?.state
        if (locData) {
            if (locData?.country) {
                let id = ""
                let formattedCountry = locData?.country?.toLowerCase()?.trim()
                for (let [key, value] of Object.entries(appointmentIdDetails)) {
                    if (key?.toLowerCase()?.trim() === formattedCountry) {
                        id = value;
                        break;
                    }
                }
                if (id) {
                    setAppointmentId(id)
                } else {
                    setAppointmentId(appointmentIdDetails?.Default)
                }

            }
        }

        const script = document.createElement("script");

        script.src = "https://cal.waymore.io/appointment.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <div className="appointmentContainer">
            <div className="content-container">
                <div className="appointment-div">
                    <div className="elementor-widget-container">
                        <div
                            className="ea-widget"
                            data-url={`https://cal.waymore.io/index.php/booking/${appointmentId}`}
                        ></div>
                    </div>
                </div>
            </div>
            )
        </div >
    );
}

export default Appointment;
