export const currency_symbols = (currency) => {

    switch (currency) {
        case 'USD': // US Dollar
            return '$';
        case 'EUR': // Euro
            return '€';
        case 'CRC': // Costa Rican Colón
            return '₡';
        case 'GBP': // British Pound Sterling
            return '£';
        case 'ILS': // Israeli New Sheqel
            return '₪';
        case 'INR': // Indian Rupee
            return '₹';
        case 'JPY': // Japanese Yen
            return '¥';
        case 'KRW': // South Korean Won
            return '₩';
        case 'NGN': // Nigerian Naira
            return '₦';
        case 'PHP': // Philippine Peso
            return '₱';
        case 'PLN': // Polish Zloty
            return 'zł';
        case 'PYG': // Paraguayan Guarani
            return '₲';
        case 'THB': // Thai Baht
            return '฿';
        case 'UAH': // Ukrainian Hryvnia
            return '₴';
        case 'VND': // Vietnamese Dong
            return '₫';
        default:
            return currency;

    }
};

export const nonServiceNames = ['name', 'isWebsite']