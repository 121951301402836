import axios from 'axios'

export const getCountry = async () => {
    const countryURL = process.env.REACT_APP_COUNTRY_URL
    try {

        let response = await axios.get(countryURL);
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const getServicePrice = async (country) => {
    const countryURL = `/api/waymore_service_price/${country}`
    try {

        let response = await axios.get(countryURL);
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const getInitialFormData = async () => {
    const formURL = `/api/waymore_initial_form`
    try {

        let response = await axios.get(formURL)
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}
export const getFormData = async (data) => {
    const formURL = `/api/waymore_form`
    try {

        let response = await axios.get(formURL, {
            params: data
        });
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const getFormDataSorted = async (data) => {
    const formURL = `/api/waymore_form_sorted`
    try {

        let response = await axios.get(formURL, {
            params: data
        });
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const getOptionalFormData = async (data) => {
    const formURL = `/api/waymore_Optional_form`
    try {

        let response = await axios.get(formURL, {
            params: data
        });
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const getContentData = async () => {
    const contentURL = `/api/waymore_content`
    try {

        let response = await axios.get(contentURL);
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const waymoreEvents = async (data) => {
    const eventsURL = `/api/waymore_Events`
    try {

        let response = await axios.post(eventsURL, data);

        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const getIndustryIdentifier = async (data) => {
    const eventsURL = `/api/waymore_industry_identifier/${data}`
    try {

        let response = await axios.get(eventsURL);

        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const getClientToken = async () => {
    const eventsURL = `/api/initialize_payment`
    try {

        let response = await axios.get(eventsURL);

        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const processPayment = async (data) => {
    try {
        var config = {
            method: 'post',
            url: `/api/process_payment`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        let response = await axios(config);

        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const verifyCaptcha = async (token) => {
    try {
        var config = {
            method: 'post',
            url: `/api/verifyCaptcha`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                token
            }
        };

        let response = await axios(config);

        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }
}

export const uploadRecording = async (chunks) => {
    try {
        let response = await axios.post('/api/uploadRecording',chunks, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }
}