import React, { useEffect, useState } from 'react'
import { changeTitle, ModifyTitle } from '../../Utilities/helperFunction'
import SelectButton from '../Select-button'
import arrowCostIcon from '../../Assets/arrowCostIcon.svg'
import './style.scss'

function RadioButton({ currency, viberMinimumCost, addNextData, mock, index, rightIcon, initialValues, setValue, maintainValues, setMaintainValues, symbol, finalTotalCost, minusPercent, isCostFormat, titleArray, isService }) {
    const [title, setTitle] = useState(mock?.title)
    let name = ''
    if (initialValues && initialValues[index - 1]) {
        name = initialValues[index - 1]
    }

    const changeCostFormatTitle = () => {
        setTitle(ModifyTitle(mock?.title, symbol, finalTotalCost, finalTotalCost))
    }

    const handleRadio = (e) => {
        setValue(initialValues[index - 1], e)
        setMaintainValues({ ...maintainValues, [`${name}`]: e })
        addNextData(e, index, mock?._id, true)
    }

    useEffect(() => {
        if (mock?.name === "costFormat") {

        } else {
            setTitle(changeTitle(mock?.title, maintainValues));
        }
    }, [])
    useEffect(() => {
        if (mock?.name === "costFormat") {
            changeCostFormatTitle()
        }
    }, [minusPercent])

    return (
        <div onClick={() => (index)} className='radio-container'>
            <div className='radio-title'>

                {mock?.name?.includes("costFormat") ? <div className='cost-suggestions'><h1><img src={arrowCostIcon} /><span className='cost-title' dangerouslySetInnerHTML={{ __html: title }} /></h1>
                    {maintainValues && maintainValues?.services?.includes("viber") && maintainValues["viberMessageCount"] && (maintainValues["viberMessageCount"] < 10000) &&
                        <h1><img src={arrowCostIcon} /> <span className='cost-title'>{`We do not recommend using viber as a communication channel in your campaign mix for the time being.
                    Viber as a channel has minimum monthly fee ${viberMinimumCost ? viberMinimumCost : 'X'} ${symbol} , and it will not brake even in your use case.
                    Instead we propose using SMS with a link redirecting to a landing page created from WayMore Platform as a best alternative approach.`}</span></h1>}
                    <h1><img src={arrowCostIcon} /><span className='cost-title'> Avoid needless expenses. Use Validation Services to delete invalid contacts from your list.</span></h1>
                </div>
                    : <h1 className='title'><span>{index}<img src={rightIcon} /></span>{title}</h1>}
            </div>

            <div className={`radio-button-container ${isService ? 'service' : ""}`}>{mock.options.map((moc, i) => <div className="radio-button" > <SelectButton titleArray={titleArray} country={maintainValues?.country} currency={currency} isCostFormat={isCostFormat} label={moc?.label} value={moc?.value} multiSelect={false} newValue={name} trueValue={maintainValues[name] ? maintainValues[name] : []} handleRadio={handleRadio} index={i} mock={mock} symbol={symbol} finalTotalCost={finalTotalCost} maintainValues={maintainValues} isService={isService} /></div>)}</div>
        </div >

    )
}

export default RadioButton